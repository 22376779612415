.promo-ticker-container {
  width: 100%;
  overflow: hidden;
  /* background-color: #fff; */
  /* background-image: linear-gradient(155deg, rgb(229, 176, 124) 0%, rgb(229, 176, 124) 41%,rgb(198, 157, 133) 41%, rgb(198, 157, 133) 42%,rgb(167, 139, 142) 42%, rgb(167, 139, 142) 44%,rgb(136, 120, 151) 44%, rgb(136, 120, 151) 45%,rgb(105, 101, 159) 45%, rgb(105, 101, 159) 61%,rgb(74, 83, 168) 61%, rgb(74, 83, 168) 78%,rgb(43, 64, 177) 78%, rgb(43, 64, 177) 100%),linear-gradient(322deg, rgb(229, 176, 124) 0%, rgb(229, 176, 124) 41%,rgb(198, 157, 133) 41%, rgb(198, 157, 133) 42%,rgb(167, 139, 142) 42%, rgb(167, 139, 142) 44%,rgb(136, 120, 151) 44%, rgb(136, 120, 151) 45%,rgb(105, 101, 159) 45%, rgb(105, 101, 159) 61%,rgb(74, 83, 168) 61%, rgb(74, 83, 168) 78%,rgb(43, 64, 177) 78%, rgb(43, 64, 177) 100%),linear-gradient(33deg, rgb(229, 176, 124) 0%, rgb(229, 176, 124) 41%,rgb(198, 157, 133) 41%, rgb(198, 157, 133) 42%,rgb(167, 139, 142) 42%, rgb(167, 139, 142) 44%,rgb(136, 120, 151) 44%, rgb(136, 120, 151) 45%,rgb(105, 101, 159) 45%, rgb(105, 101, 159) 61%,rgb(74, 83, 168) 61%, rgb(74, 83, 168) 78%,rgb(43, 64, 177) 78%, rgb(43, 64, 177) 100%),linear-gradient(315deg, rgb(229, 176, 124) 0%, rgb(229, 176, 124) 41%,rgb(198, 157, 133) 41%, rgb(198, 157, 133) 42%,rgb(167, 139, 142) 42%, rgb(167, 139, 142) 44%,rgb(136, 120, 151) 44%, rgb(136, 120, 151) 45%,rgb(105, 101, 159) 45%, rgb(105, 101, 159) 61%,rgb(74, 83, 168) 61%, rgb(74, 83, 168) 78%,rgb(43, 64, 177) 78%, rgb(43, 64, 177) 100%),linear-gradient(41deg, rgb(229, 176, 124) 0%, rgb(229, 176, 124) 41%,rgb(198, 157, 133) 41%, rgb(198, 157, 133) 42%,rgb(167, 139, 142) 42%, rgb(167, 139, 142) 44%,rgb(136, 120, 151) 44%, rgb(136, 120, 151) 45%,rgb(105, 101, 159) 45%, rgb(105, 101, 159) 61%,rgb(74, 83, 168) 61%, rgb(74, 83, 168) 78%,rgb(43, 64, 177) 78%, rgb(43, 64, 177) 100%),linear-gradient(90deg, rgb(192, 100, 79),rgb(56, 158, 172)); background-blend-mode:overlay,overlay,overlay,overlay,overlay,normal;:display; */
  /* background-image: linear-gradient(90deg, rgb(189, 254, 246) 0%, rgb(189, 254, 246) 3%,rgb(178, 217, 230) 3%, rgb(178, 217, 230) 17%,rgb(166, 179, 214) 17%, rgb(166, 179, 214) 18%,rgb(155, 142, 199) 18%, rgb(155, 142, 199) 33%,rgb(144, 105, 183) 33%, rgb(144, 105, 183) 44%,rgb(132, 67, 167) 44%, rgb(132, 67, 167) 73%,rgb(121, 30, 151) 73%, rgb(121, 30, 151) 100%),linear-gradient(112.5deg, rgb(189, 254, 246) 0%, rgb(189, 254, 246) 3%,rgb(178, 217, 230) 3%, rgb(178, 217, 230) 17%,rgb(166, 179, 214) 17%, rgb(166, 179, 214) 18%,rgb(155, 142, 199) 18%, rgb(155, 142, 199) 33%,rgb(144, 105, 183) 33%, rgb(144, 105, 183) 44%,rgb(132, 67, 167) 44%, rgb(132, 67, 167) 73%,rgb(121, 30, 151) 73%, rgb(121, 30, 151) 100%),linear-gradient(0deg, rgb(189, 254, 246) 0%, rgb(189, 254, 246) 3%,rgb(178, 217, 230) 3%, rgb(178, 217, 230) 17%,rgb(166, 179, 214) 17%, rgb(166, 179, 214) 18%,rgb(155, 142, 199) 18%, rgb(155, 142, 199) 33%,rgb(144, 105, 183) 33%, rgb(144, 105, 183) 44%,rgb(132, 67, 167) 44%, rgb(132, 67, 167) 73%,rgb(121, 30, 151) 73%, rgb(121, 30, 151) 100%),linear-gradient(45deg, rgb(189, 254, 246) 0%, rgb(189, 254, 246) 3%,rgb(178, 217, 230) 3%, rgb(178, 217, 230) 17%,rgb(166, 179, 214) 17%, rgb(166, 179, 214) 18%,rgb(155, 142, 199) 18%, rgb(155, 142, 199) 33%,rgb(144, 105, 183) 33%, rgb(144, 105, 183) 44%,rgb(132, 67, 167) 44%, rgb(132, 67, 167) 73%,rgb(121, 30, 151) 73%, rgb(121, 30, 151) 100%),linear-gradient(90deg, rgb(131, 102, 35),rgb(201, 0, 133)); background-blend-mode:overlay,overlay,overlay,overlay,normal; */
  /* background: linear-gradient(to right, #4a00e0, #8e2de2); */
  background-image: linear-gradient(112.5deg, rgb(250, 109, 130) 0%, rgb(250, 109, 130) 3%,rgb(219, 107, 130) 3%, rgb(219, 107, 130) 34%,rgb(187, 104, 131) 34%, rgb(187, 104, 131) 46%,rgb(156, 102, 131) 46%, rgb(156, 102, 131) 51%,rgb(124, 99, 132) 51%, rgb(124, 99, 132) 52%,rgb(93, 97, 132) 52%, rgb(93, 97, 132) 76%,rgb(61, 94, 133) 76%, rgb(61, 94, 133) 84%,rgb(30, 92, 133) 84%, rgb(30, 92, 133) 100%),linear-gradient(157.5deg, rgb(250, 109, 130) 0%, rgb(250, 109, 130) 3%,rgb(219, 107, 130) 3%, rgb(219, 107, 130) 34%,rgb(187, 104, 131) 34%, rgb(187, 104, 131) 46%,rgb(156, 102, 131) 46%, rgb(156, 102, 131) 51%,rgb(124, 99, 132) 51%, rgb(124, 99, 132) 52%,rgb(93, 97, 132) 52%, rgb(93, 97, 132) 76%,rgb(61, 94, 133) 76%, rgb(61, 94, 133) 84%,rgb(30, 92, 133) 84%, rgb(30, 92, 133) 100%),linear-gradient(45deg, rgb(250, 109, 130) 0%, rgb(250, 109, 130) 3%,rgb(219, 107, 130) 3%, rgb(219, 107, 130) 34%,rgb(187, 104, 131) 34%, rgb(187, 104, 131) 46%,rgb(156, 102, 131) 46%, rgb(156, 102, 131) 51%,rgb(124, 99, 132) 51%, rgb(124, 99, 132) 52%,rgb(93, 97, 132) 52%, rgb(93, 97, 132) 76%,rgb(61, 94, 133) 76%, rgb(61, 94, 133) 84%,rgb(30, 92, 133) 84%, rgb(30, 92, 133) 100%),linear-gradient(90deg, rgb(71, 69, 135),rgb(241, 26, 171)); background-blend-mode:overlay,overlay,overlay,normal;
  border-bottom: 1px solid #ccc;
  padding: 3px 0;
}

.promo-ticker-track {
  display: inline-block;
  white-space: nowrap;
  animation: ticker-scroll 30s linear infinite;
}

.promo-ticker-item {
  display: inline-block;
  margin-right: 80px;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  color: #fff;
}
.promotion-img img{
  width: 100%;
}

/* Animação para o ticker */
@keyframes ticker-scroll {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
}
.promo-ticker-track:hover {
  animation-play-state: paused;
}
